import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../assets/Logo.png';
import '../styles/Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';

const Navbar = ({ setCurrentPage }) => {
  const [isScrolling, setIsScrolling] = useState(false);
  const [showNav, setShowNav] = useState(true);
  const [showmoNav, setShowmoNav] = useState(false);
  const [showOverlayMenu, setShowOverlayMenu] = useState(true); // State for controlling the overlay menu
  const navigate = useNavigate();

  // Function to handle scrolling
  const handleScroll = () => {
    const scrollTop = window.scrollY;

    // Check if scrolling down or up
    if (scrollTop > 50 && !isScrolling) {
      setIsScrolling(true);
      setShowNav(false);
    } else if (scrollTop <= 50 && isScrolling) {
      setIsScrolling(false);
      setShowNav(true);
    } else if (scrollTop < 50 && !isScrolling) {
      setShowNav(true); // Show navbar as soon as user starts scrolling up
    }
  };

  useEffect(() => {
    // Add scroll event listener when component mounts
    window.addEventListener('scroll', handleScroll);

    // Remove scroll event listener when component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isScrolling]);

  // Function to toggle the overlay menu
  const toggleOverlayMenu = () => {
    setShowmoNav(!showmoNav);
  };

  // Function to check if user is logged in
  const checkLoggedIn = () => {
    const token = sessionStorage.getItem('token');
    return token !== null;
  };

  // Function to get username from session storage
  const getUsername = () => {
    return sessionStorage.getItem('username');
  };

  const handleModalClick = (event) => {
    // Prevent the click event from bubbling up to the overlay
    event.stopPropagation();
    setShowmoNav(false);
  };

  // Component usage example
  const isLoggedIn = checkLoggedIn();
  const username = getUsername();

  // Function to handle Ship with Us link click
  const handleShipWithUsClick = () => {
    setShowOverlayMenu(false);
    if (isLoggedIn) {
      navigate('/ShipWithUs');
    } else {
      navigate('/login');
    }
  };

  const handleNavLinkClick = (pageIndex) => {
    setCurrentPage(pageIndex);
    setShowmoNav(false); // Hide mobile navigation menu after clicking a link
  };

  return (
    <>
      {/* Mobile and Tablet Navbar */}
      <div className="mobile-navbar">
        <div className="hamburger-menu" onClick={toggleOverlayMenu}></div>
        <div className={`mobile-sidelinks ${showmoNav ? 'show' : ''}`}>
            <a onClick={() => handleNavLinkClick(0)} className='side-nav-lnks'>Home</a>
            <a onClick={() => handleNavLinkClick(4)} className='side-nav-lnks'>Packages</a>
            <a onClick={() => handleNavLinkClick(2)} className='side-nav-lnks'>Services</a>
            <a onClick={() => handleNavLinkClick(3)} className='side-nav-lnks'>Projects</a>
            <a onClick={() => handleNavLinkClick(5)} className='side-nav-lnks'>FAQ</a>
            <Link to='/blog' className='side-nav-lnks'>Blog</Link>
          </div>
        
        {/* Use showOverlayMenu state to conditionally render the overlay menu */}
        {showOverlayMenu && (
          <div className='overlay-menu' onClick={handleModalClick}>
            <div onClick={() => handleNavLinkClick(0)} className='logo-img'>
              <img src={logo} alt='logoimgee' />
              wedevs
            </div>
            <div className='sidelinks'>
              <a onClick={() => handleNavLinkClick(0)} className='side-nav-lnks'>Home</a>
              <a onClick={() => handleNavLinkClick(4)} className='side-nav-lnks'>Packages</a>
              <a onClick={() => handleNavLinkClick(2)} className='side-nav-lnks'>Services</a>
              <a onClick={() => handleNavLinkClick(3)} className='side-nav-lnks'>Projects</a>
              <a onClick={() => handleNavLinkClick(5)} className='side-nav-lnks'>FAQ</a>
              <Link to='/blog' className='side-nav-lnks'>Blog</Link>
            </div>
            <div className="social-icons">
              <a href="https://www.instagram.com/direct/t/17848334877149969" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} className="icon" />
              </a>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Navbar;

import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import logo from '../assets/Logo.png'; // Import logo image
import '../styles/Logo.css';

function Logo() {
  return (
    <Link to="/" className='logo-container'> {/* Use Link component with "to" prop */}
      <img loading='lazy' src={logo} alt='logo-main' />
    </Link>
  );
}

export default Logo;

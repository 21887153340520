import React, { useState } from 'react';
import '../styles/FAQ.css';
import homeImage from '../assets/port-img5.jpg';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  // Arrays of questions and answers
  const questions = [
    'How much does a website cost, and what\'s included in the fee?',
    'Does the website development fee include hosting and domain registration?',
    'How long will it take to build my website?',
    'What information do I need to provide for the website content?',
    'Can you assist with photography for my website?',
    'Will my website be mobile-friendly and responsive?',
  ];

  const answers = [
    'The cost varies based on complexity. Our fee covers design, development, basic SEO, and initial setup. Hosting and domain registration may be separate.',
    'Typically, hosting and domain registration are separate. We can assist in selecting a hosting provider and guide you through the domain registration process.',
    'Timelines vary based on complexity. A simple site might take a few weeks (4 to 6 weeks), while a complex e-commerce site could take a few months (1 to 3 months). We\'ll provide a detailed timeline during the planning phase.',
    'We\'ll need details about your business, services, and any specific content you want on the site. High-quality images, logos, and written content are essential for a polished result.',
    'While we don\'t provide photography services, we can recommend professionals or guide you on selecting and preparing images for your website.',
    'Yes, all our websites are designed to be responsive, ensuring a seamless experience across various devices, including mobile phones and tablets.',
  ]; 

  const handleQuestionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq">
    <div className="faq-container">
    <h1>FAQ</h1>
      <div className="faq-list">
        {questions.map((question, index) => (
          <div key={index} className="faq-item">
            <div className="question" onClick={() => handleQuestionClick(index)}>
              <div>{question}</div>
              <div className="icon">{activeIndex === index ? '-' : '+'}</div>
            </div>
            {activeIndex === index && (
              <div className="answer">
                {answers[index]}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};

export default FAQ;

import React, { useState } from 'react';
import '../styles/Packages.css';
import simplesite from '../assets/simple-img.png';
import complexsite from '../assets/complex-img.png';
import weddingsite from '../assets/wedding-img.png';

const Packages = () => {
  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleDropdown = (type) => {
    setOpenDropdown((prev) => (prev === type ? null : type));
  };

  return (
    <section className="packages" id="packages">
      <h1>Our Packages</h1>
      
      <div className="package">
        <img src={simplesite} alt="Simple Site" className="package-image" />
        <div className="package-details">
          <h2>Simple Sites</h2>
          <p>GH₵2000 - GH₵4000</p>
          <a>Perfect for personal or small business use</a>
          <div className={`dropdown ${openDropdown === 'simple' ? 'show' : ''}`}>
            <li>Responsive design for mobile devices.</li>
            <li>Up to 5 static pages (e.g., Home, About Us, Services, Contact).</li>
            <li>Basic contact form.</li>
            <li>Customizable color scheme.</li>
            <li>One-time SEO optimization.</li>
          </div>
          <br />
          <a href="#" className="dropdown-arrow" onClick={() => toggleDropdown('simple')}>more..</a>
        </div>
      </div>

      <div className="package">
        <img src={complexsite} alt="Complex Site" className="package-image" />
        <div className="package-details">
          <h2>Complex Sites</h2>
          <p>GH₵5000 - GH₵13000</p>
          <a>Advanced sites with e-commerce features.</a>
          <div className={`dropdown ${openDropdown === 'complex' ? 'show' : ''}`}>
            <li>Custom design tailored to your brand.</li>
            <li>Responsive design for optimal viewing on various devices.</li>
            <li>Up to 15 dynamic pages (e.g., Home, About Us, Products, Services, Contact).</li>
            <li>Full e-commerce functionality with secure payment gateways.</li>
            <li>Integration of inventory management system.</li>
            <li>Advanced SEO optimization.</li>
          </div>
          <br />
          <a href="#" className="dropdown-arrow" onClick={() => toggleDropdown('complex')}>more..</a>
        </div>
      </div>

      <div className="package">
        <img src={weddingsite} alt="Wedding Site" className="package-image" />
        <div className="package-details">
          <h2>Wedding Websites</h2>
          <p>GH₵3500 - GH₵4500</p>
          <a>Specialized websites for weddings.</a>
          <div className={`dropdown ${openDropdown === 'wedding' ? 'show' : ''}`}>
            <li>Customized wedding themes and designs.</li>
            <li>Photo and video gallery to showcase special moments.</li>
            <li>RSVP and guestbook functionality.</li>
            <li>Event details and countdown timer.</li>
            <li>Integration with social media platforms for easy sharing.</li>
          </div>
          <br />
          <a href="#" className="dropdown-arrow" onClick={() => toggleDropdown('wedding')}>more..</a>
        </div>
      </div>
    </section>
  );
};

export default Packages;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../styles/Articles.css';
import articleplaceholder from '../assets/blog-img.jpg';
import Logo from '../components/Logo';
import homeImage from '../assets/homesect-img.jpg';


const Blog = () => {
  const [articles, setArticles] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get('https://p2pbackendserver-six.vercel.app/api/we-articles');
        setArticles(response.data);
      } catch (error) {
        console.error('Failed to fetch articles', error);
      }
    };

    fetchArticles();
  }, []);

  // Filter articles based on search term
  const filteredArticles = articles.filter(article => {
    const searchRegex = new RegExp(searchTerm, 'i');
    return (
      searchRegex.test(article.title) ||
      searchRegex.test(article.description) ||
      searchRegex.test(article.content)
    );
  });

  return (
    <div className="articles-container">
        <Logo />
            <div className="home">
                <div className="overlay"><h1>Articles</h1></div>
                <img className='homeimage' src={homeImage} loading='lazy' alt="legal-Home-img" />
            </div>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search by title, description, or content"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="article-content">
      <div className="sidebar">
        <h2>Recent Articles</h2>
        <div className="tab-container">
          {articles.slice(0, 5).map(article => (
            <div key={article._id} className="tab">
              <Link to={`/article/${article._id}`}>
                {article.title}
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div className="main-content">
        <div className="articles-grid">
          {filteredArticles.map(article => (
            <Link to={`/article/${article._id}`} key={article._id} className="article-card">
              <img src={articleplaceholder} alt="Article" className="article-image" />
              <h2>{article.title}</h2>
              <p>{article.description}</p>
            </Link>
          ))}
        </div>
      </div>
    </div>
    </div>
  );
};

export default Blog;

// App.js
import React, { useState, useEffect } from 'react';
import { useLocation, Route, Routes } from 'react-router-dom';
import Homepage from './pages/Homepage';
import ArticlesDetails from './pages/ArticlesDetails';
import CreateArticle from './pages/CreateArticle';
import EditArticle from './pages/EditArticle';
import Blog from './pages/Blog';
import ReactGA from 'react-ga';
import { Toaster } from 'react-hot-toast';
import Slid from './components/Slid';
import './App.css';

const App = () => {
  const location = useLocation();

  useEffect(() => {
    // Track page view on route change
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);


  useEffect(() => {
    // Track page view on route change
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div className='intro-box'>
        <p>wedevs-gh</p>
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <div>
      <Toaster position='top-right' toastOptions={{ duration: 2000 }} />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/article/:id" element={<ArticlesDetails />} />
        <Route path="/create-article" element={<CreateArticle />} />
        <Route path="/edit/:id" element={<EditArticle />} />
        <Route path="/slid" element={<Slid />} />
      </Routes>
    </div>
  );
};

export default App;

import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/fall-animation.css'; // Import the animation CSS
import 'react-awesome-slider/dist/custom-animations/fold-out-animation.css';
import 'react-awesome-slider/dist/custom-animations/open-animation.css';
import 'react-awesome-slider/dist/custom-animations/fold-out-animation.css';
import 'react-awesome-slider/dist/custom-animations/scale-out-animation.css';
import p2plogistics from '../assets/p2plogistics.jpg';
import image2 from '../assets/homesect-img.jpg';

const SliderComponent = () => {
  return (
    <section id="slider" className="revoslider-wrap clearfix bgcolor-black">
    <AwesomeSlider
        animation="openAnimation" // Apply fadeOut animation
        organicArrows={false}
        bullets={false}
        infinite={true}
        interval={1000} // Set slider interval to make it automatic
      >
        <div data-src={p2plogistics}>
          {/* Add your captions and other elements here */}
        </div>
        <div data-src={image2}>
          {/* Add your captions and other elements here */}
        </div>
        <div data-src="images/site-images/95.jpg">
          {/* Add your captions and other elements here */}
        </div>
        <div data-src="images/site-images/97.jpg">
          {/* Add your captions and other elements here */}
        </div>
        <div data-src="images/site-images/52.jpg">
          {/* Add your captions and other elements here */}
        </div>
        <div data-src="images/site-images/zaytoun 74.jpg">
          {/* Add your captions and other elements here */}
        </div>
      </AwesomeSlider>
    </section>
  );
};

export default SliderComponent;

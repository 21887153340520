// components/EditArticle.js
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useNavigate } from 'react-router-dom'; 

const EditArticle = () => {
  const { id } = useParams();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [content, setContent] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch article details from the database based on id
    // Simulated article for demonstration
    const articleData = { id: 1, title: 'Article 1', description: 'Description of article 1', content: 'Content of article 1' };
    setTitle(articleData.title);
    setDescription(articleData.description);
    setContent(articleData.content);
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform submission logic, e.g., send data to the database
    // After successful submission, redirect to articles page
    navigate('/blog');
    
  };

  return (
    <div>
      <h1>Edit Article</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Title:</label>
          <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
        </div>
        <div>
          <label>Description:</label>
          <input type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
        </div>
        <div>
          <label>Content:</label>
          <textarea value={content} onChange={(e) => setContent(e.target.value)} />
        </div>
        <button type="submit">Save</button>
      </form>
    </div>
  );
};

export default EditArticle;

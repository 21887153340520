import React, { useState } from 'react';
import '../styles/SlantedDiv.css';
import image1 from '../assets/skrentalimg.webp';
import image2 from '../assets/p2plogistics.jpg';
import image3 from '../assets/chowshackoffiicial.png';
import image4 from '../assets/theneds.jpg';
import image5 from '../assets/theabs.jpg';

// Combine all image imports into a single object for easy lookup
const images = {
  image1,
  image2,
  image3,
  image4,
  image5,
};

const SlantedDivWithApp = () => {
  const [showDetails, setShowDetails] = useState(null);

  const handleClick = (index) => {
    setShowDetails(showDetails === index ? null : index);
  };

  const handleDetailsClick = (url) => {
    window.open(url, '_blank');
  };

  const containers = [
    {
      bgColor: '#193c54',
      leftContent: 'SK RENTALS',
      detailTitle: 'SK RENTALS',
      detailContent: 'A car rental Web and Mobile application for a local rental company within the environs of Tema, Accra Ghana',
      detailBgImage: 'image1',
      url: 'https://skrentals.we-devsgh.online/'
    },
    {
      bgColor: '#2e9635',
      leftContent: 'P2P LOGISTICS',
      detailTitle: 'P2P LOGISTICS',
      detailContent: 'A logistics service application for a company that offers affordable and fast shipping from the UK to Ghana',
      detailBgImage: 'image2',
      url: 'https://www.p2plogisticsgh.com/'
    },
    {
      bgColor: '#ff6347',
      leftContent: 'CHOWSHACK',
      detailTitle: 'CHOWSHACK',
      detailContent: 'A food delivery web application that allows users to access local cuisine in their vicinity and enables registered users to sell food after completing an approval process',
      detailBgImage: 'image3',
      url: 'https://chowshackgh.pythonanywhere.com/'
    },
    {
      bgColor: '#1e90ff',
      leftContent: 'THE NEDS',
      detailTitle: '#TheNeds',
      detailContent: 'The wedding website of the Neds',
      detailBgImage: 'image4',
      url: 'https://ned-x-efua-wed.we-devsgh.online/'
    },
    {
      bgColor: '#ffa500',
      leftContent: 'THE ABLADES',
      detailTitle: '#TheAblades',
      detailContent: 'The wedding website of the Ablades',
      detailBgImage: 'image5',
      url: 'https://joseph-x-geraldine.we-devsgh.online/'
    },
  ];

  return (
    <div className='deets-higher'>
      {containers.map((container, index) => (
        <div className='deets-main' key={index}>
          <div className="deets-container" onClick={() => handleClick(index)}>
            <div className="left">
              <div className={`right-arrow ${showDetails === index ? 'rotate' : ''}`}>
                <h2>{'>'}</h2>
              </div>
              <h2>{container.leftContent}</h2>
            </div>
            <div className="right">
              {/* Placeholder for any content you want to add in the right section */}
            </div>
          </div>
          <div
            className={`details ${showDetails === index ? 'show' : ''}`}
            style={{ backgroundImage: `url(${images[container.detailBgImage]})` }}
            onClick={() => handleDetailsClick(container.url)}
          >
            <div className="details-overlay">
              <h2>{container.detailTitle}</h2>
              <p className="detail-text">{container.detailContent}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SlantedDivWithApp;

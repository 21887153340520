import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Homepage.css';
import Navbar from '../components/Navbar';
import Slider from '../components/Slider';
import FAQ from '../components/FAQ';
import { Fade } from 'react-reveal';
import Packages from './Packages';
import Deets from '../components/Deets';

import myGif from '../assets/comp.gif';

const Homepage = () => {
    const [currentPage, setCurrentPage] = useState(0);

    const nextPage = () => {
      setCurrentPage(currentPage => Math.min(currentPage + 1, 5));
    };

    const prevPage = () => {
        setCurrentPage(currentPage => Math.max(currentPage - 1, 0));
    };

    return (
      <div className="container">
        {currentPage !== 1 && currentPage !== 3 && <Navbar setCurrentPage={setCurrentPage} />}

        
        <div className="page1" style={{ display: currentPage === 0 ? 'block' : 'none' }}>
          <div className='p-overlay'></div>
          <div className="content-page1">
            <div className="left">
            <Fade left duration={2000}>
              <h1>We build websites that are simple to navigate and easy to manage.</h1>
              </Fade>
              <Fade bottom duration={2000}>
              <p>Your one-stop solution for affordable digital web and business services.</p>
                </Fade>
              <a href="https://www.instagram.com/direct/t/17848334877149969" target="_blank">
              <button className='btn'>
                <svg viewBox="0 0 16 16" className="bi bi-instagram" fill="currentColor" height="16" width="16" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"></path>
                </svg>
                <span>Instagram</span>
              </button>
                </a>
            </div>
          </div>
          <button className='next1' onClick={nextPage}>Next</button>
        </div>




        <div className="page2" style={{ display: currentPage === 1 ? 'block' : 'none' }}>
            <div className='twofacepage'>
            <img src={myGif} alt="Cool GIF" />
            <h1>YOU THINK IT, WE MAKE IT HAPPEN!</h1>
            </div>
             
          <div className='service-navs'>
            <button className='prev' onClick={prevPage}>Previous</button>
            <button className='next' onClick={nextPage}>Next</button>
          </div>
        </div>

        <Fade left duration={1000}>
          <div className="page3" style={{ display: currentPage === 2 ? 'block' : 'none' }}>

            <div className="content-page3">
            <div className='headert'>
              <h2>Our Services</h2>
            </div>
              <div className="card">
                <div className="header"></div>
                <div className="info">
                  <p className="title">Web Development</p>
                  <p>Our web development services are crafted to meet your unique needs. We specialize in creating custom websites that align with your business goals and user expectations. From responsive design to seamless functionality, we ensure a tailored online presence for your brand.</p>
                </div>
                <div className="footer"></div>
              </div>

              <div className="card">
                <div className="header"></div>
                <div className="info">
                  <p className="title">SEO</p>
                  <p>Search Engine Optimization (SEO) is crucial for increasing your website's visibility on search engines. Our SEO services focus on optimizing your website's structure, content, and performance. We employ strategic techniques to improve rankings, drive organic traffic, and enhance your online presence.</p>
                </div>
                <div className="footer"></div>
              </div>

              <div className="card">
                <div className="header"></div>
                <div className="info">
                  <p className="title">Revamping & Redesign</p>
                  <p>Is your existing website in need of a makeover? Our revamping and redesigning services breathe new life into your online presence. We analyze user experience, incorporate modern design trends, and revamp the visual appeal to give your site a fresh, contemporary look.</p>
                </div>
                <div className="footer"></div>
              </div>

              <div className="card">
                <div className="header"></div>
                <div className="info">
                  <p className="title">Wedding Websites</p>
                  <p>Capture the magic of your special day with our specialized wedding websites. We create personalized platforms that showcase your love story, event details, and memories. From elegant designs to user-friendly interfaces, our wedding websites ensure a beautiful digital experience for you and your guests.</p>
                </div>
                <div className="footer"></div>
              </div>
            </div>

            <div className='service-navs'>
              <button className='previous' onClick={prevPage}>Previous</button>
              <button className='next' onClick={nextPage}>Next</button>
            </div>
          </div>
        </Fade>

        <div className="page4" style={{ display: currentPage === 3 ? 'block' : 'none' }}>
          <h2>Our Work</h2>
          <Deets />
          <div className='service-navs'>
            <button className='prev' onClick={prevPage}>Previous</button>
            <button className='next' onClick={nextPage}>Next</button>
          </div>
        </div>

        <div className="page5" style={{ display: currentPage === 4 ? 'block' : 'none' }}>
          <Packages />
          <div className='service-navs'>
            <button className='prev' onClick={prevPage}>Previous</button>
            <button className='next' onClick={nextPage}>Next</button>
          </div>
        </div>

        <div className="page6" style={{ display: currentPage === 5 ? 'block' : 'none' }}>
          <FAQ />
          <button className='prev1' onClick={prevPage}>Previous</button>
        </div>
      </div>
    );
};

export default Homepage;
